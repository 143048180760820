







































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RankingHotelCompsetGroup from '@/modules/cluster/components/ranking/ranking-hotel-compset-group.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';

@Component({
    components: {
        RankingHotelCompsetGroup,
        ProviderCard,
    },
})
export default class RankingChainList extends Vue {
    @Inject(ClusterServiceS)
    clusterService!: ClusterService;

    @Inject(ClusterRankingServiceS)
    private clusterRankingService!: ClusterRankingService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    isFirstLoading = true;

    get skeleton() {
        return this.clusterRankingService.isLoading;
    }

    get providers() {
        return this.clusterRankingService.providers;
    }

    get hotels() {
        if (this.clusterRankingService.clusterRankingsData) {
            this.clusterRankingService.clusterRankingsData
                .sort((a, b) => a.hotelName.localeCompare(b.hotelName));
        }
        return this.clusterRankingService.clusterRankingsData;
    }

    mounted() {
        document.addEventListener('scroll', this.handleScroll);
    }

    beforeDestroy() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const minDistanseToBottom = 50;
        const scrollMax = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight,
        );

        const scrollCurrent = Math.round(window.innerHeight + Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        const isBottomOfWindow = Math.abs(scrollMax - scrollCurrent) <= minDistanseToBottom;

        if (isBottomOfWindow) {
            this.isFirstLoading = false;
            this.clusterService.loadMoreData('ranking');
        }
    }

    getLogo(provider: string) {
        return this.helperService.providerLogo(provider);
    }
}
