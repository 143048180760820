
















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RankingChainHeader from '@/modules/cluster/components/ranking/ranking-chain-header.vue';
import RankingChainList from '@/modules/cluster/components/ranking/ranking-chain-list.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import RankingClusterActions from '../components/ranking/ranking-cluster-actions.vue';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        PageWrapper,
        RankingChainList,
        RankingChainHeader,
        RankingClusterActions,
    },
})
export default class RankingClusterPage extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    beforeMount() {
        this.clusterRankingService.resetLoading();
        this.clusterService.saveCurrentProvider(this.clusterRankingService.providers[0]);
    }
}
