



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, {
    CompsetsServiceS,
} from '@/modules/compsets/compsets.service';
import DocumentFiltersService, {
    DocumentFiltersServiceS,
} from '@/modules/document-filters/document-filters.service';
import RankingService, {
    RankingServiceS,
} from '@/modules/ranking/ranking.service';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import ClusterRankingService, {
    ClusterRankingServiceS,
} from '@/modules/cluster/cluster-ranking.service';
import { Location } from 'vue-router';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RankingChainRange from './ranking-chain-range.vue';
import RankingChainProvider from './ranking-chain-provider.vue';
import ClusterService, { ClusterServiceS } from '../../cluster.service';

@Component({
    components: {
        RankingChainProvider,
        RankingChainRange,
        CustomPopover,
    },
})
export default class RankingHotelCompsetGroup extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RankingServiceS) private rankingService!: RankingService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Number })
    hotelId!: number;

    @Prop({ type: Boolean })
    skeleton!: number;

    get hotel() {
        return this.clusterRankingService.getHotelBy({ hotelId: this.hotelId })
        || {
            compsets: null, hotelName: this.helperService.fill(16), hotelId: null, compsetMain: null,
        };
    }

    get mainCompset() {
        if (!this.hotel.compsets) {
            return null;
        }
        return this.hotel.compsets.find(
            compset => compset.type === COMPSET_TYPE.MEDIAN,
        );
    }

    get targetRoute() {
        return {
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(this.hotelId),
            },
        } as Location;
    }

    async goToHotel() {
        this.clusterService.switchCurrentHotel(this.hotelId);

        if (this.hotelId !== this.userService.currentHotelId) {
            this.rankingService.storeState.document = null;
            this.compsetsService.storeState.compsets = [];
            this.documentFiltersService.storeState.settings.compsetId = null;
        }

        await this.$router.push(this.targetRoute);
    }
}
