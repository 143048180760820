












































import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScoreRating from '@/modules/common/components/ui-kit/score-rating.vue';
import RankingModel from '@/modules/ranking/models/ranking-document.model';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import ChainRankingBreakdown from './ranking-chain-breakdown.vue';

@Component({
    components: {
        ProviderCard,
        ChainRankingBreakdown,
        ScoreRating,
    },
})
export default class RankingChainProvider extends Vue {
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    @Prop({
        type: String,
        required: true,
    })
    compsetId!: string;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    @Prop({
        type: Number,
        required: false,
    })
    hotelId?: number;

    @Prop({
        type: Object,
        required: false,
    })
    compsetMain?: RankingModel;

    readonly newReviews: number = 30;

    get rankingData() {
        if (!this.compsetMain) {
            return null;
        }
        if (!this.hotelId) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider]) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider][this.hotelId]) {
            return null;
        }
        return this.compsetMain.providers[this.provider][this.hotelId];
    }

    get maxScore() {
        return this.providersService.getMaxScore(this.provider) || 5;
    }

    get myHotelName(): string | null {
        if (!this.hotelId) {
            return null;
        }

        return this.hotelsService.getHotelName(this.hotelId, true);
    }
}
